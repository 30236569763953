import React from 'react';

import Layout from '../components/layout';
import { Link } from 'gatsby';

const NotFoundPage = () => (
	<Layout>
		<h1>Pagina no encontrada</h1>
		<Link to="/">Volver al inicio</Link>
	</Layout>
);

export default NotFoundPage;
