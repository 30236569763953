import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

const Header = ({ siteTitle }) => (
	<header
		style={{
			background   : `#fff`,
			marginBottom : `1.45rem`,
			textAlign    : `center`
		}}
	>
		<div
			style={{
				margin   : `0 auto`,
				maxWidth : 960,
				padding  : `1.45rem 1.0875rem 1rem`
			}}
		>
			<h1 style={{ margin: `0 0 8px` }}>
				<Link
					to="/"
					style={{
						color          : `#222`,
						textDecoration : `none`
					}}
				>
					{siteTitle}
				</Link>
			</h1>
			<h2>Médica especialista en Ginecologia</h2>
		</div>
	</header>
);

Header.propTypes = {
	siteTitle : PropTypes.string
};

Header.defaultProps = {
	siteTitle : ``
};

export default Header;
